import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'gatsby';
import { useIntl } from 'react-intl';
import { Form, Formik, Field } from 'formik';
import '../../utils/yupIntlSetup';
import * as yup from 'yup';
import { useLocation } from '@reach/router';
import PropTypes from 'prop-types';
import format from 'date-fns/format';

// Components
import Card from '../../components/Card';
import FormikPersist from '../../components/fields/FormikPersist';
import StepNavigation from '../../components/StepNavigation';
import Input from '../../components/fields/Input';
import Container from '../../components/Container';
import Aside from '../../components/Aside';
import Icon from '../../components/Icon';
import Main from '../../components/Main';
import BirthdayPicker from '../../components/fields/BirthdayPicker/BirthdayPicker';
import PhoneInput from '../../components/fields/PhoneInput';
import SubTitleWithIcon from '../PersonalInfo/SubTitleWithIcon';
import StepTitle from '../../components/StepTitle';
import PersonalInfoB from './PersonalInfoB';
import CustomerIsPassenger from './CustomerIsPassenger';
import StepObserver from '../../components/StepObserver';
// Hooks
import {
  useBuild,
  useGlobalState,
  useJsonApi,
} from '../../services/GlobalStore/GlobalStore';

import { useLocalStorageState } from '../../hooks/useLocalStorageState';
// Utils
import {
  validateBirthday,
  validatePhoneNumber,
  validateFirstname,
  validateLastname,
} from '../../utils/yupValidators';
import { submitClaim } from '../../services/resources/claims';
import { updatePassenger } from '../../services/resources/passengers';
import { updateCustomer } from '../../services/resources/customers';
import Seo from '../../components/seo';

// Translations
import messages from './messages';

import validationMessages from '../messages/validationMessages';
// Icons
import speedIcon from '../../assets/icons/speed-icon.svg';
import userIcon from '../../assets/icons/user-icon.inline.svg';
import personalInfoMessages from '../../views/PersonalInfo/messages';
import routes from '../../messages/routes';
import {
  FLIGHT_COMPENSATION,
  PRODUCT_TYPE,
  TICKET_REFUND,
} from '../../services/appLocalStorage';
import { useHandleError } from '../../services/api';
import {
  steps,
  translateRoute,
  useTranslatedNavigate,
} from '../../utils/routes';
import CountrySelect from '../../components/fields/CountrySelect';
import FlightAirportInformationStripe from '../FlightAirportInformationStripe';
import { useCoBrandingParams } from '../../hooks/useCobrandingParams';
import InfoOverlay from '../../components/InfoOverlay';
import TrustBox from '../../components/TrustBox';
import SocialProof from '../../components/SocialProof';
import SocialProofMobile from '../../components/SocialProof/Mobile';

const firstPartValidationSchema = yup.object().shape({
  firstname: validateFirstname,
  lastname: validateLastname,
  phone: validatePhoneNumber.required(),
  birthday: validateBirthday.required(),
  customer_is_passenger: yup.boolean(),
  country_of_birth: yup.string().required(),
  place_of_birth: yup.string().required(),
});

const secondPartValidationSchema = yup.object().shape({
  streetname: yup.string().required(),
  postal: yup.string().required(),
  city: yup.string().required(),
  country: yup.string().required(),
  terms_and_conditions: yup.boolean().when('customer_is_passenger', {
    is: true,
    then: yup.boolean().notRequired(),
    otherwise: yup
      .boolean()
      .required()
      .oneOf([true], validationMessages.required),
  }),
});

const validationSchema = firstPartValidationSchema.concat(
  secondPartValidationSchema
);

const PersonalInfo = ({ location }) => {
  const { formatMessage } = useIntl();
  const translatedNavigate = useTranslatedNavigate();
  const pageBottomRef = useRef(null);
  const [productType] = useLocalStorageState(PRODUCT_TYPE);
  const [claimAmount] = useLocalStorageState('claimAmount');
  const { dynamicConfig } = useCoBrandingParams();
  const country = dynamicConfig?.country?.toUpperCase();
  const locale = dynamicConfig?.locale;

  const [localStorageCustomerFlights] = useGlobalState(
    'customerFlights.booked'
  );
  const [localStorageAlternativeFlights] = useGlobalState(
    'customerFlights.alternative'
  );
  const { search, pathname } = useLocation();
  const [claimId, setClaimId] = useState();
  const [customerId, setCustomerId] = useState();
  const [handleError] = useHandleError();
  const customerFlights =
    location?.state?.customerFlights || localStorageCustomerFlights;
  const alternativeFlights =
    location?.state?.alternativeFlights || localStorageAlternativeFlights;

  const [cobrandingDossierTokenId] = useLocalStorageState(
    'cobrandingDossierTokenId'
  );
  const cobrandingDossierToken = useBuild(
    'cobrandingDossierTokens',
    cobrandingDossierTokenId
  );
  /**
   * Fix for SSR (localStorage is not available when build)
   */
  useEffect(() => {
    setClaimId(localStorage.getItem('yo:claimId'));
    setCustomerId(localStorage.getItem('yo:customerId'));
  }, []);

  const { data: claim, get } = useJsonApi(`/claims/${claimId}`, {
    credentials: 'include',
    cachePolicy: 'no-cache',
    queryParams: {
      include: 'passengers,customer',
    },
  });

  const isCongratulationsPage = pathname.includes(
    translateRoute(routes[steps.CongratulationsBasepath])
  );

  useEffect(() => {
    if (isCongratulationsPage && search.includes('sso_success'))
      localStorage.setItem('isLoggedIn', 'true');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (claimId) get();
  }, [claimId, get]);

  const initialValues = useRef({
    customer_is_passenger: true,
  });

  function navigateBack() {
    translatedNavigate(['/', routes.checkCompensation]);
  }

  function navigateNext(values, data) {
    if (values.customer_is_passenger) {
      translatedNavigate([
        '/',
        routes.congratulationsBasepath,
        routes.permission,
      ]);
    } else if (!values.customer_is_passenger) {
      translatedNavigate(
        [
          '/',
          routes.congratulationsBasepath,
          data?.claim_number,
          routes.questionnaire,
        ],
        {
          state: { data },
        }
      );
    }
  }

  async function handleSubmit(values, { setSubmitting }) {
    if (customerId) {
      await updateCustomer(customerId, {
        ...values,
        country: { id: values.country },
        // We sending that data to backend - but backend doesn't store it - because customer cannot have this in db
        // To confusing to hide the fields place_of_birth and country_of_birth if user clicked that user is a customer
        // Better to send  it to backend because if it is needed just backend needs to change something
        countryOfBirth: { id: values.country_of_birth },
      }).catch(error => {
        handleError(error);
        setSubmitting(false);
      });
    }
    if (claimId && values.customer_is_passenger) {
      await updatePassenger({
        passenger: claim?.[0]?.passengers[0],
        values: {
          ...values,
          claims: [{ id: claimId }],
          country: { id: values.country },
          countryOfBirth: { id: values.country_of_birth },
        },
      })
        .then(() => navigateNext(values, claim?.[0]))
        .catch(error => {
          handleError(error);
          setSubmitting(false);
        });
    } else if (!claimId && !values.customer_is_passenger) {
      await submitClaim({
        customerFlights,
        alternativeFlights,
        customerIsPassenger: values.customer_is_passenger,
        handleError,
        cobrandingPassengers: cobrandingDossierToken?.passengers,
      })
        .then(data => navigateNext(values, data))
        .catch(error => {
          handleError(error);
          setSubmitting(false);
        });
    } else {
      navigateNext(values);
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      validateOnMount
      initialValues={initialValues.current}
      validationSchema={validationSchema}
    >
      {({ setFieldValue, values }) => (
        <Container>
          <StepObserver
            stepName={steps.CongratulationsBasepath}
            customerFlights={customerFlights}
          />
          <Seo
            title={messages.seoTitle}
            description={messages.seoDescription}
          />
          <Main>
            <Form>
              <Card onlyDesktop>
                <div className="flex justify-center sm:justify-between items-center text-center">
                  <StepTitle className={'mb-10'}>
                    {cobrandingDossierTokenId ? (
                      <span className="text-black">
                        {formatMessage(messages.cobrandingTitle, {
                          name: dynamicConfig?.siteName,
                        })}
                        <br />
                      </span>
                    ) : null}
                    {productType === FLIGHT_COMPENSATION &&
                      formatMessage(messages.personalInfoCompensationTitle, {
                        amount: (
                          <span>
                            €
                            {parseInt(
                              cobrandingDossierToken?.amount || claimAmount,
                              0
                            )}
                          </span>
                        ),
                      })}
                    {productType === TICKET_REFUND &&
                      formatMessage(messages.personalInfoTicketRefundTitle)}
                  </StepTitle>
                  <InfoOverlay description={messages.asideText} />
                </div>
                <h2 className="mb-20 mt-10 sm:my-0 mx-10 sm:mx-0 text-center sm:text-left font-normal">
                  {productType === FLIGHT_COMPENSATION &&
                    formatMessage(messages.personalInfoCompensationSubTitle)}
                  {productType === TICKET_REFUND &&
                    formatMessage(messages.personalInfoTicketRefundSubTitle)}
                </h2>
                <Card onlyMobile>
                  {cobrandingDossierTokenId ? (
                    <>
                      <p className="uppercase text-sm text-gray-250 mb-5">
                        {formatMessage(messages.flightDetailsSubtitle)}
                      </p>
                      <div
                        data-testid="flight-info"
                        className="bg-gray-100 py-10 px-15 text-sm text-gray-250 flex"
                      >
                        <FlightAirportInformationStripe
                          flights={customerFlights}
                          element={'span'}
                          removeDefaultStyles={true}
                          className="font-bold flex-shrink inline-block"
                        />
                        <span
                          className="inline-block flex-grow text-right"
                          style={{
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {formatMessage(messages.flightInfo, {
                            flight_code: customerFlights?.[0]?.flight_code,
                            flight_date: customerFlights?.[0]
                              ?.departure_datetime
                              ? format(
                                  new Date(
                                    customerFlights?.[0]?.departure_datetime
                                  ),
                                  'dd/MM/yyyy'
                                )
                              : '-',
                          })}
                        </span>
                      </div>
                    </>
                  ) : null}
                  <div className="fieldset sm:flex-row items-center pb-5 justify-between sm:mt-40 sm:border-b border-gray-200">
                    <SubTitleWithIcon
                      icon={userIcon}
                      className="fill-primary text-lg"
                    >
                      {formatMessage(
                        values.customer_is_passenger
                          ? messages.passengerOne
                          : messages.personalDetails
                      )}
                    </SubTitleWithIcon>
                    <CustomerIsPassenger
                      className="hidden sm:block text-gray-300 text-sm"
                      formatMessage={formatMessage}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                  <div className="fieldset sm:flex-row">
                    <Input
                      name="firstname"
                      type="text"
                      label={messages.firstName}
                      placeholder={messages.firstNamePlaceholder}
                    />
                    <Field name="customer_is_passenger" type="hidden" />
                    <Input
                      wrapperClassName="mt-25 sm:mt-0"
                      name="lastname"
                      type="text"
                      label={messages.lastName}
                      placeholder={messages.lastNamePlaceholder}
                    />
                  </div>
                  <div className="fieldset sm:flex-row mt-25">
                    <PhoneInput
                      name="phone"
                      label={messages.phone}
                      placeholder={messages.phonePlaceholder}
                      portal={country}
                      locale={locale}
                    />
                    <BirthdayPicker
                      name="birthday"
                      autoComplete="off"
                      label={personalInfoMessages.birthDate}
                    />
                  </div>
                  <div className="fieldset sm:flex-row mt-25">
                    <Input
                      name="place_of_birth"
                      label={personalInfoMessages.placeOfBirth}
                      placeholder={personalInfoMessages.placeOfBirthPlaceholder}
                    />
                    <CountrySelect
                      name="country_of_birth"
                      label={personalInfoMessages.countryOfBirth}
                      autoComplete="off"
                      placeholder={
                        personalInfoMessages.countryOfBirthPlaceholder
                      }
                    />
                  </div>
                  <PersonalInfoB
                    isFirstPartValid={firstPartValidationSchema.isValidSync(
                      values
                    )}
                    pageBottomRef={pageBottomRef}
                    country={country}
                    values={values}
                    formatMessage={formatMessage}
                  />
                </Card>
                {!firstPartValidationSchema.isValidSync(values) && (
                  <CustomerIsPassenger
                    className="sm:hidden mt-35 mx-10 text-black"
                    formatMessage={formatMessage}
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                )}
                {firstPartValidationSchema.isValidSync(values) && (
                  <StepNavigation
                    className="sm:hidden"
                    onBackClick={
                      cobrandingDossierTokenId ? undefined : navigateBack
                    }
                    backButtonMessage={formatMessage(
                      messages.personalInfoBackButton
                    )}
                    step={steps.CongratulationsBasepath}
                    dataLayerPushOnContinue
                    dataLayerPushOnBack
                    submitButtonMessage={formatMessage(messages.submitContinue)}
                  />
                )}
              </Card>
              <StepNavigation
                className="hidden sm:block"
                onBackClick={
                  cobrandingDossierTokenId ? undefined : navigateBack
                }
                backButtonMessage={formatMessage(
                  messages.personalInfoBackButton
                )}
                submitButtonMessage={formatMessage(messages.submitContinue)}
                dataLayerPushOnContinue
                dataLayerPushOnBack
                step={steps.CongratulationsBasepath}
              />
              <TrustBox className="my-20" />
              <SocialProofMobile />
              <FormikPersist localStorageKey={'personalInfo'} />
              <div ref={pageBottomRef} />
            </Form>
            {cobrandingDossierTokenId ? (
              <Link
                to={translateRoute(['/', routes[steps.Unsubscribe]])}
                data-testid="unsubscribe-link"
              >
                {formatMessage(messages.cobrandingUnsubscribeLink)}
              </Link>
            ) : null}
          </Main>
          <Aside showSocialProof>
            <SocialProof>
              <Icon src={speedIcon} className="mb-20" />
              {formatMessage(messages.asideText)}
            </SocialProof>
          </Aside>
        </Container>
      )}
    </Formik>
  );
};

PersonalInfo.propTypes = {
  location: PropTypes.object,
};

export default PersonalInfo;
